<template>
  <v-card class="mx-0 pa-2" elevation="0" v-if="prestataire !== null" outlined>
    <v-row no-gutters>
      <v-col cols="12">
        <v-row justify="space-between">
          <v-col cols="3">
            <h3 class="secondary--text">{{
                prestataire.raison_sociale
              }}</h3>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="3">
            <v-btn v-if="isResponsableOrAdmin || hasPermission('change_profile')" text disabled color="grey">
              <v-icon>mdi-pencil</v-icon>
              Modifier
            </v-btn>
            <v-dialog v-model="deleteDialog" v-if="isResponsableOrAdmin || hasPermission('delete_profile')" width="500">
              <template v-slot:activator="{on, attrs}">
                <v-btn text disabled v-on="on" v-bind="attrs" color="grey">
                  <v-icon>mdi-trash-can-outline</v-icon>
                  Supprimer
                </v-btn>
              </template>
              <v-card>
                <v-card-title class="headline red lighten-1 white--text">
                  Supprimer l'intervenant
                </v-card-title>
                <v-card-text class="body-1 mt-2">
                  Confirmer la suppression de l'intervenant ?
                </v-card-text>
                <v-card-actions>
                  <v-btn text color="red">Supprimer l'intervenant
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="6" xl="3">
        <detail-card icon="mdi-map-marker" name="Adresse" long
                     :information="hasPrestataire()
                                       && prestataire.adresse !== null
                                       ? prestataire.adresse : 'Non défini'"></detail-card>
      </v-col>
      <v-col cols="6" xl="3">
        <detail-card icon="mdi-google-maps" name="Code postal"
                     :information="hasPrestataire()
                                   ? prestataire.code_postale
                                   : 'Non défini'"></detail-card>
      </v-col>
      <v-col cols="6" xl="3">
        <detail-card icon="mdi-office-building" name="SIRET"
                     :information="hasPrestataire()
                                   ? prestataire.siret
                                   : 'Non défini'"></detail-card>
      </v-col>
      <v-col cols="12" xl="6">
        <detail-card icon="mdi-ticket" name="Reçoit directement les tickets"
                     :information="hasPrestataire()
                                   ? prestataire.direct_ticket ? 'Oui' : 'Non'
                                   : 'Non défini'"></detail-card>
      </v-col>


      <v-col cols="12">
        <v-row no-gutters>
          <v-col cols="12" v-if="prestataire.contrats">
            <v-row v-if="prestataire.contrats.length > 0">
              <v-col cols="12">
                <span class="font-weight-bold">Contrats du prestataire</span>
              </v-col>
              <v-col cols="4" v-for="contrat in prestataire.contrats" :key="contrat.id">
                <detail-card clickable
                             :name="getContractType(contrat)" long
                             :information="contrat.name"
                             @click.native="$router.push('/contracts/' + contrat.id)"
                             icon="mdi-file-document-multiple-outline"/>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-row no-gutters>
          <v-col cols="12" v-if="prestataire.intervenants">
            <v-row v-if="prestataire.intervenants.length > 0">
              <v-col cols="12">
                <span class="font-weight-bold">Intervenants du prestataire</span>
              </v-col>
              <v-col cols="4" v-for="intervenant in prestataire.intervenants" :key="intervenant.id">
                <detail-card clickable
                             :name="intervenant.fullname"
                             :information="formatMetiers(intervenant.metiers)"
                             @click.native="$router.push('/intervenants/' + intervenant.user_id)"
                             icon="mdi-account"/>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-divider></v-divider>
    </v-row>
  </v-card>
</template>

<script>
import DetailCard from "@/components/base/DetailCard";
import {AuthMixin} from "@/mixins/util/AuthMixin";
import {ContractMixin} from "@/mixins/contracts/ContractMixin";

export default {
  name: "PrestataireView",
  mixins: [AuthMixin, ContractMixin],
  components: {DetailCard},
  async mounted() {
    if (this.$route.params?.id !== undefined) {
      await this.$store.dispatch("prestataires/getPrestataireById", this.$route.params?.id);
    }
  },
  data() {
    return {
      deleteDialog: false,
    }
  },
  computed: {
    prestataire() {
      return this.$store.getters["prestataires/getSelectedPrestataire"];
    }
  },
  methods: {
    hasPrestataire() {
      return true;
    },
    formatMetiers(metiers) {
      let metiersText = "";
      for (const metier of metiers) {
        metiersText += metier.name + ", "
      }
      return metiersText.slice(0, -2);
    }
  }
}
</script>

<style scoped>

</style>